var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.stillLoading ? _c('div', {
    staticClass: "loading-page"
  }, [_c('vue-simple-spinner', {
    attrs: {
      "size": "large",
      "message": "Fetching Data ..."
    }
  })], 1) : _c('div', [_c('div', {
    staticClass: "flex justify-between items-center mb-6"
  }, [_c('h1', {
    staticClass: "text-3xl font-bold"
  }, [_vm._v("Class")]), _c('div', {
    staticClass: "flex gap-3"
  }, [_c('Button', {
    attrs: {
      "buttonText": "Class Attributes",
      "type": "tertiary"
    },
    on: {
      "action": _vm.toClassAttributes
    }
  }), _c('Button', {
    attrs: {
      "buttonText": "Add New Class"
    },
    on: {
      "action": _vm.addData
    }
  })], 1)]), _c('div', {
    staticClass: "bg-white p-4 shadow-small rounded-lg card"
  }, [_c('div', {
    staticClass: "flex justify-start items-center gap-4 mb-6"
  }, [_c('div', {
    staticClass: "w-80"
  }, [_c('TextField', {
    attrs: {
      "type": "text",
      "label": "Search",
      "enterKeyAction": _vm.onSearch,
      "borderEnabled": "",
      "placeholder": "Search by Class Name..."
    },
    model: {
      value: _vm.options['name'],
      callback: function callback($$v) {
        _vm.$set(_vm.options, 'name', $$v);
      },
      expression: "options['name']"
    }
  })], 1), _c('div', {
    staticClass: "w-60"
  }, [_c('label', {
    staticClass: "text-xs pl-3 font-medium text-neutral-500 text-left block mb-1"
  }, [_vm._v("Filter By Program")]), _c('Dropdown', {
    attrs: {
      "options": _vm.programOptions,
      "optionLabel": "name",
      "placeholder": "Select Program"
    },
    on: {
      "change": function change($event) {
        return _vm.searchByProgram();
      }
    },
    model: {
      value: _vm.options['program'],
      callback: function callback($$v) {
        _vm.$set(_vm.options, 'program', $$v);
      },
      expression: "options['program']"
    }
  })], 1)]), _c('vue-good-table', {
    attrs: {
      "mode": "remote",
      "pagination-options": {
        enabled: true,
        mode: 'pages',
        perPage: _vm.perPage,
        position: 'bottom',
        perPageDropdown: [5, 10],
        dropdownAllowAll: false,
        nextLabel: 'next',
        prevLabel: 'prev',
        rowsPerPageLabel: 'Rows per page',
        ofLabel: 'of',
        pageLabel: 'page',
        // for 'pages' mode
        allLabel: 'All'
      },
      "totalRows": _vm.totalRecords,
      "rows": _vm.data,
      "columns": _vm.columns,
      "sort-options": {
        enabled: false
      },
      "row-style-class": _vm.rowStyleClassFn,
      "styleClass": "vgt-table bordered my-table"
    },
    on: {
      "on-page-change": _vm.onPageChange,
      "on-per-page-change": _vm.onPageChange
    },
    scopedSlots: _vm._u([{
      key: "table-row",
      fn: function fn(props) {
        return [props.column.field == 'btn' ? _c('div', {
          staticClass: "flex flex-col gap-2 whitespace-nowrap"
        }, [_c('div', {
          staticClass: "flex justify-center items-center"
        }, [_c('Button', {
          staticClass: "mr-2",
          attrs: {
            "type": "secondary",
            "size": "icon",
            "forIcon": {
              height: '24',
              width: '24'
            },
            "icon": function icon() {
              return import(
              /* webpackChunkName: 'icon' */
              '@/components/Icons/GlobeOutline');
            },
            "tooltip": {
              show: true,
              position: '100%',
              text: 'Bilingual'
            }
          },
          on: {
            "action": function action($event) {
              return _vm.toBilingualPages(props.row.id);
            }
          }
        }), _c('Button', {
          staticClass: "mr-2",
          attrs: {
            "type": "secondary",
            "size": "icon",
            "forIcon": {
              height: '24',
              width: '24',
              color: 'yellow'
            },
            "icon": function icon() {
              return import(
              /* webpackChunkName: 'icon' */
              '@/components/Icons/Document');
            },
            "tooltip": {
              show: true,
              position: '100%',
              text: 'Category'
            }
          },
          on: {
            "action": function action($event) {
              return _vm.categoryMaster(props.row.id);
            }
          }
        }), _c('Button', {
          staticClass: "mr-2",
          attrs: {
            "type": "tertiary",
            "size": "icon",
            "forIcon": {
              color: 'yellow'
            },
            "icon": function icon() {
              return import(
              /* webpackChunkName: 'icon' */
              '@/components/Icons/Pencil');
            },
            "tooltip": {
              show: true,
              position: '170%',
              text: 'Edit'
            }
          },
          on: {
            "action": function action($event) {
              return _vm.editMaster(props.row.id);
            }
          }
        }), _c('Button', {
          attrs: {
            "type": "delete",
            "size": "icon",
            "icon": function icon() {
              return import(
              /* webpackChunkName: 'icon' */
              '@/components/Icons/Trash');
            },
            "tooltip": {
              show: true,
              position: '140%',
              text: 'Delete'
            }
          },
          on: {
            "action": function action($event) {
              return _vm.deleteMaster(props.row.id);
            }
          }
        })], 1)]) : props.column.field == 'deliveryTypes' ? _c('div', _vm._l(_vm.formatDeliveryTypes(props.formattedRow[props.column.field]), function (type, index) {
          return _c('span', {
            key: 'type-' + index,
            staticClass: "block mb-1"
          }, [_c('span', {
            staticClass: "text-center block whitespace-nowrap text-white text-sm font-bold py-1 px-3 rounded-md",
            class: [type === 'Webinar' ? 'bg-neutral' : 'bg-neutral-300']
          }, [_vm._v(_vm._s(type))])]);
        }), 0) : props.column.field == 'customer_name' ? _c('div', [_c('span', {
          staticStyle: {
            "display": "block",
            "white-space": "nowrap"
          }
        }, [_vm._v(_vm._s(props.formattedRow[props.column.field]))])]) : props.column.field === 'tugas_description' ? _c('div', [_vm._v(" " + _vm._s(_vm._f("truncate")(props.formattedRow[props.column.field], 100)) + " ")]) : props.column.field === 'nama' ? _c('div', [_c('div', {
          staticClass: "nama-kelas",
          domProps: {
            "innerHTML": _vm._s(props.formattedRow[props.column.field])
          }
        })]) : props.column.field === 'created_date' ? _c('div', [_c('span', {
          staticStyle: {
            "display": "block",
            "white-space": "nowrap"
          }
        }, [_vm._v(" " + _vm._s(_vm.formatDate(props.formattedRow[props.column.field]).date) + " ")]), _c('span', {
          staticClass: "block text-sm text-neutral-300"
        }, [_vm._v(" " + _vm._s(_vm.formatDate(props.formattedRow[props.column.field]).time) + " ")])]) : props.column.field == 'exam_url' ? _c('span', [_c('a', {
          attrs: {
            "href": props.row.exam_url,
            "target": "_blank"
          }
        }, [_vm._v(" " + _vm._s(props.row.exam_url))])]) : props.column.field == 'exam_url_array' ? _c('span', _vm._l(props.row.exam_url_array, function (item_exam, index) {
          return _c('a', {
            key: 'eurl-' + index,
            attrs: {
              "href": item_exam,
              "target": "_blank"
            }
          }, [_vm._v(" " + _vm._s(item_exam))]);
        }), 0) : props.column.field == 'exam_sheet' ? _c('span', [props.row.exam_sheet ? _c('a', {
          attrs: {
            "href": 'https://docs.google.com/spreadsheets/d/' + props.row.exam_sheet,
            "target": "_blank"
          }
        }, [_vm._v(" " + _vm._s(props.row.exam_sheet))]) : _c('span', [_vm._v("-")])]) : props.column.field === 'quiz_exercise_id_array' ? _c('span', [_vm._v(" " + _vm._s(props.row.quiz_exercise_id_array && props.row.quiz_exercise_id_array.length > 0 ? props.row.quiz_exercise_id_array : '-') + " ")]) : _c('span', [_vm._v(" " + _vm._s(props.formattedRow[props.column.field] || '-') + " ")])];
      }
    }])
  })], 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }