<template>
  <div>
    <div v-if="stillLoading" class="loading-page">
      <vue-simple-spinner size="large" message="Fetching Data ..."></vue-simple-spinner>
    </div>
    <div v-else>
      <div class="flex justify-between items-center mb-6">
        <h1 class="text-3xl font-bold">Class</h1>
        <div class="flex gap-3">
          <Button buttonText="Class Attributes" type="tertiary" @action="toClassAttributes" />
          <Button buttonText="Add New Class" @action="addData" />
        </div>
      </div>
      <div class="bg-white p-4 shadow-small rounded-lg card">
        <div class="flex justify-start items-center gap-4 mb-6">
          <div class="w-80">
            <TextField type="text" label="Search" :enterKeyAction="onSearch" v-model="options['name']" borderEnabled placeholder="Search by Class Name..." />
          </div>
          <div class="w-60">
            <label class="text-xs pl-3 font-medium text-neutral-500 text-left block mb-1">Filter By Program</label>
            <Dropdown :options="programOptions" optionLabel="name" v-model="options['program']" @change="searchByProgram()" placeholder="Select Program" />
          </div>
        </div>
        <vue-good-table
          mode="remote"
          :pagination-options="{
            enabled: true,
            mode: 'pages',
            perPage: perPage,
            position: 'bottom',
            perPageDropdown: [5, 10],
            dropdownAllowAll: false,
            nextLabel: 'next',
            prevLabel: 'prev',
            rowsPerPageLabel: 'Rows per page',
            ofLabel: 'of',
            pageLabel: 'page', // for 'pages' mode
            allLabel: 'All'
          }"
          :totalRows="totalRecords"
          :rows="data"
          :columns="columns"
          :sort-options="{
            enabled: false
          }"
          @on-page-change="onPageChange"
          @on-per-page-change="onPageChange"
          :row-style-class="rowStyleClassFn"
          styleClass="vgt-table bordered my-table"
        >
          <template slot="table-row" slot-scope="props">
            <div v-if="props.column.field == 'btn'" class="flex flex-col gap-2 whitespace-nowrap">
              <div class="flex justify-center items-center">
                <Button
                  class="mr-2"
                  type="secondary"
                  size="icon"
                  :forIcon="{ height: '24', width: '24' }"
                  :icon="() => import(/* webpackChunkName: 'icon' */ '@/components/Icons/GlobeOutline')"
                  :tooltip="{ show: true, position: '100%', text: 'Bilingual' }"
                  @action="toBilingualPages(props.row.id)"
                />
                <Button
                  class="mr-2"
                  type="secondary"
                  size="icon"
                  :forIcon="{ height: '24', width: '24', color: 'yellow' }"
                  :icon="() => import(/* webpackChunkName: 'icon' */ '@/components/Icons/Document')"
                  :tooltip="{ show: true, position: '100%', text: 'Category' }"
                  @action="categoryMaster(props.row.id)"
                />
                <Button
                  class="mr-2"
                  type="tertiary"
                  size="icon"
                  :forIcon="{ color: 'yellow' }"
                  :icon="() => import(/* webpackChunkName: 'icon' */ '@/components/Icons/Pencil')"
                  :tooltip="{ show: true, position: '170%', text: 'Edit' }"
                  @action="editMaster(props.row.id)"
                />
                <Button
                  type="delete"
                  size="icon"
                  :icon="() => import(/* webpackChunkName: 'icon' */ '@/components/Icons/Trash')"
                  :tooltip="{ show: true, position: '140%', text: 'Delete' }"
                  @action="deleteMaster(props.row.id)"
                />
              </div>
            </div>
            <div v-else-if="props.column.field == 'deliveryTypes'">
              <span class="block mb-1" v-for="(type, index) in formatDeliveryTypes(props.formattedRow[props.column.field])" :key="'type-' + index">
                <span class="text-center block whitespace-nowrap text-white text-sm font-bold py-1 px-3 rounded-md" :class="[type === 'Webinar' ? 'bg-neutral' : 'bg-neutral-300']">{{ type }}</span>
              </span>
            </div>
            <div v-else-if="props.column.field == 'customer_name'">
              <span style="display: block; white-space: nowrap">{{ props.formattedRow[props.column.field] }}</span>
            </div>
            <div v-else-if="props.column.field === 'tugas_description'">
              {{ props.formattedRow[props.column.field] | truncate(100) }}
            </div>
            <div v-else-if="props.column.field === 'nama'">
              <div class="nama-kelas" v-html="props.formattedRow[props.column.field]"></div>
            </div>
            <div v-else-if="props.column.field === 'created_date'">
              <span style="display: block; white-space: nowrap">
                {{ formatDate(props.formattedRow[props.column.field]).date }}
              </span>
              <span class="block text-sm text-neutral-300">
                {{ formatDate(props.formattedRow[props.column.field]).time }}
              </span>
            </div>
            <span v-else-if="props.column.field == 'exam_url'">
              <a :href="props.row.exam_url" target="_blank"> {{ props.row.exam_url }}</a>
            </span>
            <span v-else-if="props.column.field == 'exam_url_array'">
              <a v-for="(item_exam, index) in props.row.exam_url_array" :key="'eurl-' + index" :href="item_exam" target="_blank"> {{ item_exam }}</a>
            </span>
            <span v-else-if="props.column.field == 'exam_sheet'">
              <a :href="'https://docs.google.com/spreadsheets/d/' + props.row.exam_sheet" target="_blank" v-if="props.row.exam_sheet"> {{ props.row.exam_sheet }}</a>
              <span v-else>-</span>
            </span>
            <span v-else-if="props.column.field === 'quiz_exercise_id_array'">
              {{ props.row.quiz_exercise_id_array && props.row.quiz_exercise_id_array.length > 0 ? props.row.quiz_exercise_id_array : '-' }}
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] || '-' }}
            </span>
          </template>
        </vue-good-table>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { truncate } from '@/utils/string'
export default {
  name: 'Kelas',
  components: {
    Button: () => import(/* webpackChunkName: "Button" */ '@/components/Button/Button'),
    Dropdown: () => import(/* webpackChunkName: "Dropdown" */ '@/components/Dropdown/Dropdown'),
    TextField: () => import(/* webpackChunkName: "TextField" */ '@/components/Form/TextField')
  },
  filters: {
    truncate
  },
  mounted: function () {
    this.initData()
  },
  data: function () {
    return {
      searchTerm: null,
      options: {
        limit: 11,
        page: 1,
        sort: '',
        name: '',
        program: ''
      },
      programOptions: [],
      //columns: [],
      perPage: 10,
      showProgress: true,
      counterProgress: 0,
      modeForm: '',
      locked: false,
      unlockPassword: '',
      stillLoading: true,
      testingName: ''
    }
  },
  methods: {
    toClassAttributes() {
      this.$router.push('/kelas/class-attributes')
    },
    formatDeliveryTypes(data) {
      const result =
        data &&
        data.map((entry) =>
          entry
            .replace('_', '-')
            .toLowerCase()
            .replace(/^\w/, (c) => c.toUpperCase())
        )
      return result || []
    },
    formatDate(date) {
      if (date) {
        return {
          date: moment(date).local().format('DD MMM YYYY'),
          time: moment(date).local().format('HH:mm:ss')
        }
      } else {
        return '-'
      }
    },
    initData() {
      this.modeForm = this.$route.meta.mode
      this.counterProgress = 0
      const customerId = this.clientId
      this.options = { ...this.options, name: this.$route.query.name }
      let paramsTemp = queryString.stringify({
        ...this.options
      })

      this.$store.dispatch('kelas/GET_LIST_PROGRAM').then((response) => {
        this.programOptions = response.data.data
      })
      this.$store
        .dispatch('kelas/GET_LIST_KELAS', {
          masterType: this.$route.meta.name,
          params: paramsTemp,
          customerId: customerId
        })
        .then(() => {
          this.counterProgress = 100
          this.showProgress = false
          this.stillLoading = false
        })
    },
    unlock() {
      if (this.unlockPassword == this.$store.getters['customer/customer'].password) {
        this.locked = false
      } else {
        alert('Password infalid')
      }
    },
    preview(masterId) {
      this.$router.push('/kelas/' + masterId)
    },
    onPageChange(params) {
      const customerId = this.clientId
      this.options.limit = params.currentPerPage
      this.options.page = params.currentPage
      let paramsTemp = queryString.stringify({
        ...{
          limit: params.currentPerPage,
          page: params.currentPage,
          sort: ''
        },
        ...this.options
      })
      this.$store.dispatch('kelas/GET_LIST_KELAS', {
        masterType: this.$route.meta.name,
        params: paramsTemp,
        testingName: this.$route.meta.testingName,
        customerId: customerId
      })
    },
    searchByProgram() {
      this.options.program = this.options.program.code
      this.onSearch()
    },
    onSearch(event) {
      this.options.page = 1
      const customerId = this.clientId
      let paramsTemp = queryString.stringify({
        ...{
          limit: null,
          page: null,
          sort: '',
          testingName: this.$route.meta.testingName
        },
        ...this.options
      })
      this.$store.dispatch('kelas/GET_LIST_KELAS', {
        masterType: this.$route.meta.name,
        params: paramsTemp,
        testingName: this.$route.meta.testingName,
        customerId: customerId
      })
    },
    toBilingualPages(masterId) {
      this.$router.push('/kelas/bilingual/' + masterId)
    },
    categoryMaster(masterId) {
      this.$router.push('/kelas/category/' + masterId)
    },
    editMaster(masterId) {
      this.$router.push('/kelas/edit/' + masterId)
    },
    deleteMaster(masterId) {
      let paramsTemp = queryString.stringify({
        ...{
          limit: null,
          page: null,
          name: null,
          sort: '',
          testingName: this.$route.meta.testingName
        },
        ...this.options
      })

      this.$dialog
        .confirm('Are you sure you want to delete this?', {
          okText: 'Yes',
          cancelText: 'No',
          loader: true,
          animation: 'bounce'
        })
        .then((dialog) => {
          dialog.close()
          const customerId = this.clientId
          this.$store
            .dispatch('kelas/DELETE_KELAS', {
              masterType: this.$route.meta.name,
              masterId: masterId,
              customerId: customerId,
              testingName: this.$route.meta.testingName
            })
            .then((resp) => {
              if (resp.data.status == '200 OK') {
                const customerId = this.clientId
                // this.$swal(resp.data.message)
                this.$store.dispatch('kelas/GET_LIST_KELAS', {
                  masterType: this.$route.meta.name,
                  params: paramsTemp,
                  customerId: customerId
                })
              }
            })
        })
        .catch(function () {})
    },

    addData() {
      this.$router.push('/' + this.$route.meta.name + '/Add')
    },
    openDisplay() {
      this.$router.push('/display/' + this.$store.getters['customer/customer'].token_trx)
    },
    resetDisplay() {
      this.$store.dispatch('customer/RESET_TOKEN_TRX', {}).then((resp) => {
        if (resp.data.status == '200 OK') {
          // this.$swal(resp.data.status)
        }
      })
    },
    rowStyleClassFn(row) {
      let result = 'default'
      // if (row.id % 2 == 1 ){
      //     result='green'
      // } else if (row.id == 0 ){
      //     result='red'
      // }
      return result
    }
  },
  computed: {
    clientId() {
      return localStorage.getItem('client')
    },
    data: function () {
      return this.$store.getters['kelas/list_kelas'] ? this.$store.getters['kelas/list_kelas'] : []
    },
    totalRecords: function () {
      return this.$store.getters['kelas/paginate'].total ? this.$store.getters['kelas/paginate'].total : 0
    },
    columns: function () {
      let tempColumnsNew = [
        { field: 'nama', label: 'Name' },
        { field: 'id', label: 'Id' },
        { field: 'code', label: 'Code' },
        { field: 'deliveryTypes', label: 'Type' },
        { field: 'exam_sheet', label: 'Exam Sheet' },
        { field: 'max_peserta', label: 'Max Participants' },
        { field: 'min_peserta', label: 'Min Participants' },
        { field: 'customer_name', label: 'Customer' },
        { field: 'tugas_description', label: 'Task Description' },
        { field: 'quiz_exercise_id_array', label: 'Quiz Exercise Id' },
        { field: 'java_instructur_id_array', label: 'Instructor Id' },
        { field: 'created_date', label: 'Created Date' },
        { field: 'btn', label: 'Action', tdClass: 'custom-action', thClass: 'custom-action-head' }
      ]

      return tempColumnsNew
    },
    level_id: function () {
      return this.$store.getters['customer/customer'] ? this.$store.getters['customer/customer'].level_id : 0
    }
  },
  created: function () {
    this.initData()
  },
  watch: {
    $route(to, from) {
      this.showProgress = true
      this.initData()
    }
  }
}
</script>
<style lang="scss" scoped>
.red {
  background: linear-gradient(#f4f5f8, #e2133f);
}
.green {
  background: linear-gradient(#f4f5f8, #57c137);
}
.default {
  /* background: linear-gradient(#f4f5f8, #f1f3f6); */
  background-color: #fff;
}
.nama-kelas {
  min-width: 300px;
}
.card {
  &::v-deep .my-table thead tr th {
    white-space: nowrap;
  }
}
</style>
